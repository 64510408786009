const validationMixin = {
  methods: {
    async validate(showError = false, focus = false) {
      const validationElements = this.validationElements.map(
        componentOrString => {
          if (typeof componentOrString === 'string') {
            return this.$refs[componentOrString]
          }

          return componentOrString
        }
      )
      const promises = validationElements.map(component =>
        component.validate(showError, focus)
      )
      const results = await Promise.all(promises)
      const result = results.reduce(
        (accumulator, value) => accumulator && value
      )

      if (!result && focus) {
        // focus first element with validation error
        validationElements[results.findIndex(elem => !elem)].focus()
      }

      return result
    }
  },
  computed: {
    validationElements() {
      return undefined // needs to be implemented within the component
    }
  }
}

export default validationMixin
